import React, { useState, useRef } from 'react'
import {
  theme,
  ChakraProvider,
  Flex,
  HStack,
  Container,
  Spacer,
  Box,
  Center,
  Image,
  IconButton,
  Link
} from '@chakra-ui/react'
import { HamburgerIcon } from '@chakra-ui/icons'
import { NavLink } from './NavLink'
import { Builder } from './BuilderCool'
import { authorize, getApp } from './app-bridge'

function App() {

  const searchParams = new URLSearchParams(window.location.search)

  if (searchParams.has('isAuth') && searchParams.get('isAuth')) {
    authorize()
    return
  }

  const app = getApp()

  return (
    <ChakraProvider theme={theme}>
      <Box
        bg='green.700'
        w='100%'
        h={200}
        sx={{ position: 'absolute', zIndex: -1 }} />
      <Container maxW='container.xl'>
        <Flex py={4}>
          <Center>
            <Image src='logo.svg' h={8} />
          </Center>
          <Spacer />
          <IconButton
            icon={<HamburgerIcon />}
            colorScheme='whiteAlpha'
            color='whiteAlpha.900'
            variant='ghost'
            display={{ base: 'flex', md: 'none' }} />
          <Box display={{ base: 'none', md: 'flex' }}>
            <NavLink href='#'>Support</NavLink>
            <NavLink href='#'>Settings</NavLink>
          </Box>
        </Flex>
        <Box
          bg='white'
          color='blue.900'
          boxShadow='lg'
          borderRadius='md'
          minH={300}

        >
          <Builder shopifyApp ={app} />{' '}
        </Box>
      </Container>
      <Box
        bg='#333333'
        w='100%'
        h={300}
        mt={-40}
        sx={{ position: 'absolute', zIndex: -1 }}
      />
    </ChakraProvider>
  )
}
export default App
