import React, { useState, useEffect } from 'react'
import {
  Tooltip, Flex, Input, Image, HStack, VStack, Heading, Spacer, Box, FormControl, InputGroup, InputLeftElement, IconButton, InputLeftAddon,
  Checkbox, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Text, Button, useClipboard,
} from '@chakra-ui/react'

import {
  DeleteIcon, InfoOutlineIcon
} from '@chakra-ui/icons'


import { CUIAutoComplete } from 'chakra-ui-autocomplete'
import { ProductIcon, DiscountIcon } from './CustomIcons'

const domain = "https://primalharvest.com";

const countries = [
  { value: "Immune", label: "Immune Defense", qnty: 1, pvariant: "Main", id: "11111", image: "https://i.shgcdn.com/df143600-97b9-484f-a184-8557c2d9a9e0/-/format/auto/-/preview/3000x3000/-/quality/lighter/" },
  { value: "Immune2", label: "Immune Defense", qnty: 1, pvariant: "Subscription", id: "11112", image: "https://i.shgcdn.com/df143600-97b9-484f-a184-8557c2d9a9e0/-/format/auto/-/preview/3000x3000/-/quality/lighter/" },
  { value: "Gut", label: "Gut Restore", qnty: 1, pvariant: "Main", id: "21111", image: "https://cdn.shopify.com/s/files/1/0094/2925/4223/products/Gut_Restore_mockup_1_2000x.jpg?v=1635895326" },
  { value: "Gut2", label: "Gut Restore", qnty: 1, pvariant: "Subscription", id: "21112", image: "https://cdn.shopify.com/s/files/1/0094/2925/4223/products/Gut_Restore_mockup_1_2000x.jpg?v=1635895326" },
];



const BuilderPanel = props => (
  <VStack
    width='100%'
    px={{ base: 0, sm: 6 }}
    py={10}
    spacing={4}
    align='stretch'
    {...props}
  />
)

const ProductsPanel = (props) => {
  const [pickerItems, setPickerItems] = useState(props.initialState);
  const [selectedItems, setSelectedItems] = useState([]);

  const { onChange, isSub } = props

  const handleCreateItem = (item) => {
    setPickerItems((curr) => [...curr, item]);
    setSelectedItems((curr) => [...curr, item]);
  };

  const handleSelectedItemsChange = (selectedItems) => {
    if (selectedItems) {
      setSelectedItems(selectedItems);
      onChange(selectedItems)

      console.log(selectedItems)
    }
  };

  const customRender = (selected) => {
    return (
      <Flex flexDir="row" alignItems="center">
        <Image w='40px' p={1} src={selected.image} />
        <Text>{selected.label} - {selected.pvariant}</Text>
      </Flex>
    )
  }

  const listRender = (selected) => {
    return (
      <>

        <Flex flexDir="row" alignItems="center" bg='#27674908' mt={2} borderWidth='1px' borderRadius='lg'>
          <Image
            borderRadius='md'
            w='120px'
            h='120px'
            objectFit='cover'
            src={selected.image}
            alt='Product image'
            p={4}
          />
          <VStack
            spacing={0}
            align='stretch'
          ><Text fontSize='2xl'>{selected.label}</Text><Text fontSize='lg'>Variant: {selected.pvariant}</Text></VStack>
          <Spacer ></Spacer>

          {isSub && <Input width='250px' bg="white" value={selected.subid} onChange={(ev) => { selected.subid = ev.target.value; handleSelectedItemsChange(selectedItems) }} placeholder='Subscription Selling Plan ID' mr='15px' />
          }
          <NumberInput w='75px' min={1} bg="white" value={selected.qnty || 1} onChange={(value) => { selected.qnty = value; handleSelectedItemsChange(selectedItems) }}>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <IconButton variant='ghost' aria-label='Remove Product' colorScheme="#2767490d" icon={< DeleteIcon />} onClick={() => handleSelectedItemsChange(selectedItems.filter(current => current.value !== selected.value))} />
        </Flex>
      </>
    )
  }

  const customCreateItemRender = (value) => {
    return (
      <Text>
        <Box as='span'>Create</Box>{' '}
        <Box as='span' bg='red.300' fontWeight='bold'>
          "{value}"
        </Box>
      </Text>
    )
  }


  return <BuilderPanel>
    <Box px={6}>
      <Heading color="#333333">Products</Heading>
      <Text pt={2}>Select one or more products to be added to the users cart via the link.</Text>

      <FormControl id='main-product'>

        {/*<ProductSelect />*/}
        <CUIAutoComplete
          listStyleProps={{ position: 'absolute', zIndex: 2323, width: '100%' }}
          tagStyleProps={{
            rounded: 'full'
          }}
          placeholder="Select Products"
          onCreateItem={handleCreateItem}
          tagStyleProps={{ display: 'none' }}
          items={pickerItems}
          itemRenderer={customRender}
          createItemRenderer={customCreateItemRender}
          selectedItems={selectedItems}
          onSelectedItemsChange={(changes) =>
            handleSelectedItemsChange(changes.selectedItems)
          }
        />
      </FormControl>

      {!!selectedItems.length && (selectedItems.map(v => listRender(v))) || (
        <Flex
          h='80px'
          borderRadius='md'
          borderStyle='dashed'
          borderWidth='2px'
          borderColor='gray.300'
          color='gray.400'
          fontWeight={600}
          justifyContent='center'
          align='center'
        >
          Selected Products Will Show Here
        </Flex>)}
    </Box>
  </BuilderPanel >
}

export const Builder = (shopifyApp) => {
  const [isProduct, setisProduct] = useState(true);
  const [isDiscount, setisDiscount] = useState(true);
  const [specialCode, setSpecialCode] = React.useState('');
  const [redirect, setRedirect] = React.useState('')
  const [copyUrl, setCopyUrl] = React.useState('')
  const [products, setProducts] = useState([])
  const [isSub, setisSub] = useState(true);

  const handleProductChange = (state) => {

    console.log('some change happened')
    console.log(state)

    setProducts(state)
    createLink(state, redirect, specialCode)

  }

  const app = shopifyApp;

  const createLink = (products, redirect, specialCode) => {
    let discountUrl = ""
    let redirectUrl = ""

    if (specialCode !== "" && isDiscount == true) {
      if (redirect !== "") {
        discountUrl = `/discount/${specialCode}?redirect=`;
      }
      else if (specialCode !== "") { discountUrl = `/discount/${specialCode}`; }
    }
    else { discountUrl = '' };

    if (redirect !== "") {
      redirectUrl = `/${redirect}`;
    }
    else { redirectUrl = '' };


    const productsString = products.map(({ id, qnty, subid }) => `updates[${id}]=${qnty}&`).join("")



    let productUrlString = '/cart/update?' + productsString + ('return_to');
    let productUrl = '';
    if (productUrlString !== '/cart/update?return_to') {
      productUrl = '/cart/update?' + productsString + ('return_to');
    }
    else { productUrl = '' };


    console.log(productsString)
    console.log(productUrl)

    setCopyUrl([domain, productUrl, discountUrl, redirectUrl].join(""));

  }

  useEffect(() => {
    createLink(products, redirect, specialCode)
  }, [products, redirect, specialCode])

  const { hasCopied, onCopy } = useClipboard(copyUrl)

  return <>
    <BuilderPanel>
      <HStack spacing={10} px={6}>
        <Box cursor="pointer" w='50%' pos="relative" bg={isProduct ? "#48bb7812" : "#27674908"} borderColor={isProduct ? "#333333" : "#e1e2e7"} px={6} pt={5} pb={45} borderWidth='2px' borderRadius='lg' onClick={() => {
          setisProduct(!isProduct)
        }}>
          <HStack spacing={10} px={6}>
            <Box w='10%'>
              <ProductIcon w={16} h={16} aria-label='Product Option' /></Box>
            <Box w='90%'>  <Text fontSize='2xl' pt={15} fontWeight="bold" color="#333333">Product</Text>
              <Text pt={2}>Create a link which adds one or more products to the users cart and redirects them to a page.</Text>
            </Box>
          </HStack>
          <Checkbox value='purl' colorScheme='green' size='lg' pos="absolute" right="15px" top="15px" style={{ pointerEvents: "none" }} isChecked={isProduct} ></Checkbox></Box>
        <Box cursor="pointer" w='50%' pos="relative" bg={isDiscount ? "#48bb7812" : "#27674908"} borderColor={isDiscount ? "#333333" : "#e1e2e7"} px={6} pt={5} pb={45} borderWidth='2px' borderRadius='lg' onClick={() => {
          setisDiscount(!isDiscount)
        }}>
          <HStack spacing={10} px={6}>
            <Box w='10%'>
              <DiscountIcon w={16} h={16} aria-label='Discount Option' /></Box>
            <Box w='90%'> <Text fontSize='2xl' fontWeight="bold" pt={15} color="#333333">Discount Code</Text>
              <Text pt={2}>Create a link which adds a Shopify discount code to the users session and redirects them to a page.</Text>
            </Box></HStack>
          <Checkbox value='durl' colorScheme='green' size='lg' pos="absolute" right="15px" top="15px" style={{ pointerEvents: "none" }} isChecked={isDiscount}></Checkbox>
        </Box>

      </HStack>


    </BuilderPanel>
    {!!isProduct && <ProductsPanel initialState={countries} isSub={isSub} onChange={handleProductChange} />}


    <Box display={isProduct ? "block" : "none"} cursor="pointer" w="100%" borderWidth='0px' borderRadius='lg' onClick={() => {
      setisSub(!isSub)
    }}>
      <HStack spacing={2} px={6} ml={12}>
        <Text>Generate a link for subscription products?</Text>
        <Tooltip label='Subscription products require a Subscription Selling Plan ID. You can find more information in the subscription app you are using.' fontSize='md'>
          <InfoOutlineIcon />
        </Tooltip>
        <Checkbox colorScheme='green' size='lg' style={{ pointerEvents: 'none' }} isChecked={isSub}></Checkbox>
      </HStack>
    </Box>

    <BuilderPanel>
      <HStack spacing={10} px={6}>
        <Box w='50%' bg='#27674908' px={6} pb={45} borderWidth='1px' borderRadius='lg'>
          <Heading pt={35} color="#333333">Redirection</Heading>
          <Text pt={2}>Select where the user will be redirect - it can be the homapage, checkout or any other Shopify page.</Text>
          <InputGroup pt={30}>
            <InputLeftAddon>{domain}/</InputLeftAddon>
            <Input bg='white' placeholder='custom-page-url' value={redirect} onChange={(ev) => setRedirect(ev.target.value)} />
          </InputGroup></Box>

        <Box w='50%' bg='#27674908' px={6} pb={45} borderWidth='1px' borderRadius='lg' display={isDiscount ? "block" : "none"}>
          <Heading pt={35} color="#333333">Discount Code</Heading>
          <Text pt={2}>Apply a discount code to the users cart - you can add any Shopify active discount code.</Text>
          <InputGroup width='auto' pt={30}>
            <InputLeftElement
              pointerEvents='none'
              color='gray.300'
              fontSize='1.2em'
              children='%' pt={50}
            />
            <Input bg='white' value={specialCode} onChange={(ev) => setSpecialCode(ev.target.value)} placeholder='Enter Discount Code' />
          </InputGroup>
        </Box>
      </HStack>
      <Flex pt={10} px={6}>
        <Button color='white' bg='#276749' w={200}>Your Link</Button>
        <Input ml={2} value={copyUrl} isReadOnly placeholder={copyUrl} />
        <Button onClick={onCopy} ml={2} bg="#27674908">
          {hasCopied ? 'Copied' : 'Copy'}
        </Button>
      </Flex>

    </BuilderPanel>
  </>
}

