import createApp from '@shopify/app-bridge';
import { Redirect } from '@shopify/app-bridge/actions';

const { host } = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

const apiKey = '6e8303f9bb5c1210624ed49100056fba';
const redirectUri = 'https://clickcart-app-test.pages.dev/?isAuth=1';
const permissionUrl = `https://${host}/admin/oauth/authorize?client_id=${apiKey}&scope=read_products,read_content&redirect_uri=${redirectUri}`;

export const authorize = () => {
  // If the current window is the 'parent', change the URL by setting location.href
  if (window.top == window.self) {
    window.location.assign(permissionUrl);
    // If the current window is the 'child', change the parent's URL with Shopify App Bridge's Redirect action
  } else {
    const app = createApp({
      apiKey: apiKey,
      host
    });

    Redirect.create(app).dispatch(Redirect.Action.REMOTE, permissionUrl);
  }
}

export const getApp = () => createApp({
  apiKey: apiKey,
  host
});



