import React, { useState } from 'react'
import { Button } from '@chakra-ui/react'

export const NavLink = ({ children, href }) => (
  <Button
    as='a'
    href={href}
    colorScheme='whiteAlpha'
    color='whiteAlpha.900'
    variant='ghost'
  >
    {children}
  </Button>
)
